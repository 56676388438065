import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import styles from "./projects.module.scss";

const ProjectsPage = () => (
  <Layout>
    <SEO
      title="Projects"
      description="A working list of projects that I have worked on in the past. Browse through to learn more about them and find the links to them live on the Internet."
    />
    <h1>Projects</h1>
    <p>
      Below I've included a list to some of the projects that I have worked on
      in the past. Many of them are no longer as fully functional as they used
      to be, but are left online as static sites for sake of preservation.
    </p>
    <div className={styles.section}>
      <h3>
        <a href="https://www.kapwing.com" target="_blank" rel="noreferrer">
          Kapwing
        </a>
      </h3>
      <p>
        Kapwing is an online video editor. The product focuses on making content
        creation more easy and accessible. I've been working full time on this
        product in both a product and engineering capacity since 2017.
      </p>
    </div>
    <div className={styles.section}>
      <h3>
        <a href="https://www.mixfont.com" target="_blank" rel="noreferrer">
          Mixfont
        </a>
      </h3>
      <p>
        Mixfont is a modern font generator. In just a few clicks, you can browse
        different pairings of Google fonts and see them in action in a variety
        of web-friendly scenarios. This site was my first time learning more
        about SEO and how you could build a website that targeted specific
        keywords on Google - in this case, I wanted to rank for the query "font
        generator". To my surprise, this website still serves hundreds of
        visitors a day.
      </p>
    </div>
    <div className={styles.section}>
      <h3>
        <a
          href="https://www.mixfont.com/shadows"
          target="_blank"
          rel="noreferrer"
        >
          Trendy Text Shadows
        </a>
      </h3>
      <p>
        I created a collection of trendy text shadow styles that other
        developers could use in their own projects. This was a popular launch on{" "}
        <a
          href="https://www.producthunt.com/posts/trendy-css-text-shadows"
          target="_blank"
          rel="noreferrer"
        >
          Product Hunt.{" "}
        </a>
      </p>
    </div>
    <div className={styles.section}>
      <h3>
        <a
          href="https://www.normalsoftware.com"
          target="_blank"
          rel="noreferrer"
        >
          Normal Software
        </a>
      </h3>
      <p>
        Normal Software was a project that aimed to provide insights and ideas
        for techies from businesses outside of tech. We conducted interviews and
        summarized our findings on this site. The site was the{" "}
        <a
          href="https://www.producthunt.com/posts/normal-software"
          target="_blank"
          rel="noreferrer"
        >
          #1 product of the day
        </a>{" "}
        on Product Hunt in June of 2017.
      </p>
    </div>
    <div className={styles.section}>
      <h3>
        <a href="https://hotbarber.ericlu.co" target="_blank" rel="noreferrer">
          Hot Barber
        </a>
      </h3>
      <p>
        Hotbarber was a failed startup idea that focused on making it easy to
        get an affordable haircut. While the idea had some traction, it
        ultimately did not have product market fit. The story of the failure and
        some learning lessons can be found on{" "}
        <a
          href="https://www.failory.com/interview/hot-barber"
          target="_blank"
          rel="noreferrer"
        >
          Failory
        </a>
        .
      </p>
    </div>
    <div className={styles.section}>
      <h3>
        <a href="https://marathon.rovemap.com" target="_blank" rel="noreferrer">
          Boston Marathon Map
        </a>
      </h3>
      <p>
        As part of one of my early business ideas, I created a map for the
        Boston Marathon which was used by the official race organizers. The map
        was helpful for runners during the marathon to find water stations, aid
        stations, the route, and more.
      </p>
    </div>
    <div className={styles.section}>
      <h3>More links</h3>
      <p>
        I've included some more links to projects below that I may write
        descriptions about soon in the future.
      </p>
      <ul>
        <li>
          <a
            href="https://www.mixfont.com/screensaver"
            target="_blank"
            rel="noreferrer"
          >
            Windows Screensaver / WebGL explorations
          </a>
        </li>
        <li>
          <a
            href="https://basketball.ericlu.co"
            target="_blank"
            rel="noreferrer"
          >
            CS 109 Final Project: Basketball Predictions
          </a>
        </li>
        <li>
          <a
            href="https://grouptale.herokuapp.com"
            target="_blank"
            rel="noreferrer"
          >
            Grouptale
          </a>{" "}
          - the original Grouptale deliberations app, built in college, which
          still runs on a free Heroku dyno.
        </li>
      </ul>
    </div>
    <div className={styles.spacer} />
  </Layout>
);

export default ProjectsPage;
